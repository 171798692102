import { useEffect } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Image,
  Popover,
  PopoverTrigger,
  PopoverContent,
} from '@nextui-org/react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { trackPageView } from '../utils/gt';

import meusTreinos from '../assets/meusTreinos.jpg';
import alexa3 from '../assets/alexa3.jpg';
import watch from '../assets/watch.jpg';

import { ModalWelcome } from '../components/ModalWelcome';

export const Dashboard = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    trackPageView('dashboard', `${t('appUrl')}/dashboard`, '/dashboard');
  }, [t]);

  const hasWelcomeParam = !!searchParams.get('w');

  const comingSoonPopOver = (
    <PopoverContent>
      <div className="px-1 py-2 text-white/80">
        <div className="text-small font-bold">
          {t('pages.Dashboard.comingSoon')}
        </div>
        <div className="text-tiny">{t('pages.Dashboard.funcInDev')}</div>
      </div>
    </PopoverContent>
  );

  return (
    <>
      {hasWelcomeParam && (
        <ModalWelcome closeWelcome={() => setSearchParams('')} />
      )}
      <div className="m-4 sm:m-10 lg:mt-32">
        <div className="grid grid-cols-2 gap-4 mt-4 mx-0 sm:mt-10 sm:mx-auto max-w-[800px] items-center">
          <Card className="col-span-4 min-h-[160px] sm:col-span-1 w-full h-full">
            <CardBody className="justify-center items-center">
              <h1 className="text-3xl sm:text-5xl">
                {t('pages.Dashboard.dashboard')}
              </h1>
            </CardBody>
          </Card>
          <Card
            className="col-span-4 sm:col-span-1 cursor-pointer"
            isPressable
            onPress={() => navigate('/workouts')}
          >
            <Image
              isZoomed
              removeWrapper
              alt="Card background"
              className="z-0 w-full h-[300px] object-cover"
              src={meusTreinos}
            />
            <CardFooter className="absolute bottom-0 z-10">
              <p className="text-lg text-white/80 uppercase font-bold">
                {t('pages.Dashboard.myWorkouts')}
              </p>
            </CardFooter>
          </Card>
          <Card
            className="col-span-4 sm:col-span-1 cursor-pointer"
            isPressable
            onPress={() => navigate('/sync')}
          >
            <CardHeader className="absolute z-10 top-1 flex-col !items-start">
              <div className="backdrop-blur-md rounded p-1">
                <p className="text-lg text-white/80 uppercase font-bold">
                  {t('pages.Dashboard.sync')}
                </p>
              </div>
            </CardHeader>
            <Image
              isZoomed
              removeWrapper
              alt="Card background"
              className="z-0 w-full h-[300px] object-cover"
              src={alexa3}
            />
          </Card>
          <Popover placement="bottom-start">
            <PopoverTrigger>
              <Card className="col-span-4 sm:col-span-1 cursor-pointer opacity-50">
                <Image
                  removeWrapper
                  alt="Card background"
                  className="z-0 w-full h-[300px] object-cover grayscale"
                  src={watch}
                />
                <CardFooter className="absolute bottom-0 z-10">
                  <p className="text-lg text-white/80 uppercase font-bold">
                    {t('pages.Dashboard.myAccount')}
                  </p>
                </CardFooter>
              </Card>
            </PopoverTrigger>
            {comingSoonPopOver}
          </Popover>
        </div>
      </div>
    </>
  );
};
