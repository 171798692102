import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { trackPageView } from '../utils/gt';

import { Banner } from '../components/Banner';
import { SignupBanner } from '../components/SignupBanner';
import { CardSequenceUsage } from '../components/CardSequenceUsage';
import { CardSequenceBenefits } from '../components/CardSequenceBenefits';
import { Disclaimer } from '../components/Disclaimer';

export const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    trackPageView('home', `${t('appUrl')}/`, '/');
  }, [t]);

  useEffect(() => {
    const authToken = localStorage.getItem('authToken');

    if (authToken) {
      navigate('/dashboard');
    }
  }, [navigate]);

  return (
    <div className="m-4 sm:m-10 sm:mt-4">
      <Banner />
      <h1 className="text-5xl text-center mt-6 sm:mt-10">
        {t('pages.Home.heading')}
      </h1>
      <p className="text-center mt-2 text-slate-400">
        {t('pages.Home.subHeading')}
      </p>
      <CardSequenceUsage />
      <h1 className="text-5xl text-center mt-6 sm:mt-12">
        {t('pages.Home.heading2')}
      </h1>
      <p className="text-center mt-2 text-slate-400">
        {t('pages.Home.subHeading2')}
      </p>
      <CardSequenceBenefits />
      <SignupBanner />
      <Disclaimer />
    </div>
  );
};
