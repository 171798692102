import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Card, CardBody, Skeleton } from '@nextui-org/react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { trackPageView } from '../utils/gt';
import { GET_WORKOUTS } from '../graphql/queries';

import { BackButton } from '../components/BackButton';
import { Notification } from '../components/Notification';
import { WorkoutCard } from '../components/WorkoutCard';

export const Workouts = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const hasSuccessCreateParam = !!searchParams.get('sc');
  const hasSuccessUpdateParam = !!searchParams.get('su');

  const {
    data: dataWorkouts,
    loading: isLoadingWorkouts,
    refetch: refetchWorkouts,
  } = useQuery(GET_WORKOUTS);

  const workouts = dataWorkouts?.workouts?.data;

  useEffect(() => {
    trackPageView('workout', `${t('appUrl')}/workouts`, '/workouts');
  }, [t]);

  useEffect(() => {
    if (hasSuccessCreateParam) {
      toast.success(t('notifications.workoutCreated'), {
        toastId: 'workout-success',
        autoClose: 2000,
        onClose: () => setSearchParams(''),
      });
    } else if (hasSuccessUpdateParam) {
      toast.success(t('notifications.workoutUpdated'), {
        toastId: 'workout-success',
        autoClose: 2000,
        onClose: () => setSearchParams(''),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Notification />
      <div className="m-4 sm:m-10 lg:mt-20">
        <div className="grid grid-cols-2 gap-4 mt-4 mx-0 sm:mt-10 sm:mx-auto max-w-[800px] items-center">
          <div className="col-span-2">
            <BackButton routeToGoBack={'/dashboard'} />
          </div>
          {isLoadingWorkouts ? (
            <>
              <Skeleton className="col-span-2 min-h-[160px] sm:min-h-[300px] sm:col-span-1 w-full h-full" />
              <Skeleton className="col-span-2 min-h-[160px] sm:min-h-[300px] sm:col-span-1 w-full h-full" />
              <Skeleton className="col-span-2 min-h-[160px] sm:min-h-[300px] sm:col-span-1 w-full h-full" />
              <Skeleton className="col-span-2 min-h-[160px] sm:min-h-[300px] sm:col-span-1 w-full h-full" />
            </>
          ) : (
            <>
              <Card
                isPressable
                isHoverable
                onPress={() =>
                  workouts?.length === 10
                    ? toast.error(t('notifications.maxWorkoutsError'))
                    : navigate('/workout')
                }
                className="col-span-2 min-h-[160px] sm:min-h-[300px] sm:col-span-1 bg-transparent w-full h-full border-dotted border-5 border-gray-500 cursor-pointer"
              >
                <CardBody className="justify-center items-center">
                  <h1 className="text-3xl sm:text-5xl">
                    {t('pages.Workouts.newWorkout')}
                  </h1>
                </CardBody>
              </Card>
              {workouts &&
                [...workouts]
                  .sort((a, b) => {
                    const nameA = a.attributes.name.toUpperCase();
                    const nameB = b.attributes.name.toUpperCase();
                    if (nameA < nameB) {
                      return -1;
                    }
                    if (nameA > nameB) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((workout) => (
                    <WorkoutCard
                      key={`workout-${workout.id}`}
                      id={workout.id}
                      name={workout.attributes.name}
                      description={workout.attributes?.description}
                      image={workout.attributes.image}
                      refetchWorkouts={refetchWorkouts}
                    />
                  ))}
            </>
          )}
        </div>
      </div>
    </>
  );
};
