import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import {
  Tabs,
  Tab,
  Card,
  CardHeader,
  CardFooter,
  Input,
  Button,
  Select,
  SelectItem,
  Image,
  useDisclosure,
  Switch,
  cn,
  Skeleton,
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  getKeyValue,
} from '@nextui-org/react';
import { useMutation } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faImage } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import { GET_WORKOUTS } from '../graphql/queries';
import { CREATE_WORKOUT, UPDATE_WORKOUT } from '../graphql/mutations';
import { workoutImages } from '../utils/workoutImages';
import { trainingNames } from '../utils/templates';
import { trackPageView, trackEvent } from '../utils/gt';

import { BackButton } from '../components/BackButton';
import { Notification } from '../components/Notification';
import { ModalPhotoPicker } from '../components/ModalPhotoPicker';
import { ExerciseCard } from '../components/ExerciseCard';
import { ModalCreationAI } from '../components/ModalCreationAI';

export const Workout = () => {
  const { t } = useTranslation();

  const schema = yup.object({
    name: yup
      .string()
      .matches(/^[A-Ja-j]+$/, t('forms.validations.workoutName'))
      .required(t('forms.validations.workoutNameReq')),
    description: yup.string(),
  });

  const [isAIGenerated, setIsAIGenerated] = useState(false);
  const [activeTab, setActiveTab] = useState('definitions');
  const [workoutData, setWorkoutData] = useState({
    name: 'A',
    description: '',
    image: 'workout0',
    motivation: true,
    exercises: [
      {
        name: '',
        series: '3',
        reps: '12',
        interval: 60,
        howTo: '',
      },
    ],
  });
  const [alreadyTakenNames, setAlreadyTakenNames] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const {
    isOpen: isPhotoPickerOpen,
    onOpen: onPhotoPickerOpen,
    onOpenChange: onPhotoPickerOpenChange,
    onClose: onPhotoPickerClose,
  } = useDisclosure();

  const {
    isOpen: isModalIAOpen,
    onOpen: onModalIAOpen,
    onOpenChange: onModalIAOpenChange,
    onClose: onModalIAClose,
  } = useDisclosure();

  const [createWorkoutMutation, { loading: isLoadingCreateWorkout }] =
    useMutation(CREATE_WORKOUT);

  const [updateWorkoutMutation, { loading: isLoadingUpdateWorkout }] =
    useMutation(UPDATE_WORKOUT);

  const {
    data: dataWorkouts,
    loading: isLoadingWorkouts,
    refetch: refetchWorkouts,
  } = useQuery(GET_WORKOUTS);

  const workouts = dataWorkouts?.workouts?.data;

  const navigate = useNavigate();
  const { workoutId } = useParams();

  useEffect(() => {
    trackPageView('workout', `${t('appUrl')}/workout`, '/workout');
  }, [t]);

  useEffect(() => {
    if (workouts && !alreadyTakenNames) {
      const takenNames = workouts.map((workout) => workout.attributes.name);

      const availableNames = trainingNames.filter(
        (trainingName) => !takenNames.includes(trainingName)
      );
      const unavailableNames = trainingNames.filter((trainingName) =>
        takenNames.includes(trainingName)
      );

      /**
       * If there's no workoutId, it means we're creating a new workout.
       * In this case, we need to set the name as the first possible available option (based on what's currently created by the user).
       */
      if (!workoutId) {
        setWorkoutData({
          ...workoutData,
          name: availableNames[0],
        });

        setAlreadyTakenNames(unavailableNames);
      } else {
        const currentWorkout = workouts.find(
          (workout) => workout.id === workoutId
        );

        /**
         * If current account is not found it means that this is an invalid workout id
         * harcoded to the url route.
         *
         * In this scenario, the user is redirected back to the workouts page.
         */
        if (!currentWorkout) {
          navigate('/workouts');
        } else {
          setWorkoutData({
            name: currentWorkout.attributes.name,
            description: currentWorkout.attributes.description,
            image: currentWorkout.attributes.image,
            motivation: currentWorkout.attributes.motivation,
            exercises: currentWorkout.attributes.exercises,
            howTo: currentWorkout.attributes?.howTo,
          });

          /**
           * If there's an workout id, the workout being eddited should be able to keep its name
           * even if it's already taken by itself.
           */
          setAlreadyTakenNames(
            unavailableNames.filter(
              (unavailableName) =>
                unavailableName !== currentWorkout.attributes.name
            )
          );
        }
      }
    }
  }, [alreadyTakenNames, navigate, workoutData, workoutId, workouts]);

  useEffect(() => {
    if (!workoutId) {
      onModalIAOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Notification />
      <ModalCreationAI
        isOpen={isModalIAOpen}
        onOpenChange={onModalIAOpenChange}
        onClose={onModalIAClose}
        setActiveTab={setActiveTab}
        workoutData={workoutData}
        setWorkoutData={setWorkoutData}
        setIsAIGenerated={setIsAIGenerated}
      />
      <ModalPhotoPicker
        isOpen={isPhotoPickerOpen}
        onOpenChange={onPhotoPickerOpenChange}
        onClose={onPhotoPickerClose}
        workoutImages={workoutImages}
        workoutData={workoutData}
        setWorkoutData={setWorkoutData}
      />
      <div className="m-4 sm:m-10 lg:mt-20">
        <div className="grid grid-cols-2 gap-4 mt-4 mx-0 sm:mt-10 sm:mx-auto max-w-[960px] items-center">
          <div className="col-span-2">
            <BackButton routeToGoBack={'/workouts'} />
          </div>
          <div className="col-span-2">
            {isLoadingWorkouts ? (
              <div className="grid grid-cols-2 gap-4">
                <Skeleton className="col-span-2 min-h-[160px] sm:min-h-[300px] sm:col-span-1 w-full h-full" />
                <Skeleton className="col-span-2 min-h-[80px] sm:col-span-1 w-full h-[80px]" />
              </div>
            ) : (
              <Tabs
                selectedKey={activeTab}
                onSelectionChange={setActiveTab}
                aria-label="Options"
                variant="bordered"
                size="lg"
                className="flex justify-center"
                classNames={{
                  tabList: cn('w-full sm:w-auto'),
                }}
              >
                <Tab key="definitions" title={t('pages.Workout.definitions')}>
                  <div className="flex items-baseline mt-4">
                    <h1 className="text-5xl mb-6">{t('standalone.workout')}</h1>
                    <Select
                      {...register('name')}
                      selectedKeys={workoutData.name}
                      disabledKeys={alreadyTakenNames}
                      onChange={(e) => {
                        setWorkoutData({
                          ...workoutData,
                          name: e.target.value,
                        });
                      }}
                      aria-label="training-name"
                      size="lg"
                      className="max-w-[90px] ml-2"
                      classNames={{
                        value: 'text-5xl',
                      }}
                    >
                      {trainingNames.map((trainingName) => (
                        <SelectItem
                          key={trainingName}
                          value={trainingName}
                          textValue={trainingName}
                        >
                          <p className="text-white text-5xl">{trainingName}</p>
                        </SelectItem>
                      ))}
                    </Select>
                  </div>
                  <div className="grid grid-cols-2 gap-4 mt-2 sm:mt-6">
                    <Card className="col-span-2 sm:col-span-1">
                      <CardHeader className="absolute z-10 top-1 flex-col !items-end">
                        <div
                          className="backdrop-blur-md rounded p-1"
                          onClick={() => onPhotoPickerOpen()}
                        >
                          <FontAwesomeIcon icon={faImage} inverse />
                        </div>
                      </CardHeader>
                      <img
                        alt="Card background"
                        className="z-0 w-full h-[300px] object-cover"
                        src={workoutImages.find((img) =>
                          img.includes(workoutData.image)
                        )}
                      />
                    </Card>
                    <div className="col-span-2 sm:col-span-1">
                      <Input
                        {...register('description')}
                        isRequired
                        value={workoutData.description}
                        onValueChange={(description) =>
                          setWorkoutData({
                            ...workoutData,
                            description: description
                              .toLowerCase()
                              .replace(/(?:^|\s)\S/g, (char) =>
                                char.toUpperCase()
                              ),
                          })
                        }
                        errorMessage={errors?.description?.message}
                        isInvalid={!!errors?.description?.message}
                        startContent={
                          <FontAwesomeIcon icon={faPenToSquare} inverse />
                        }
                        label={t('forms.labels.workoutDescription')}
                        variant="bordered"
                        className="text-white"
                        placeholder={t('forms.placeholders.workoutDescription')}
                      />
                      <Switch
                        isSelected={workoutData.motivation}
                        onValueChange={(motivation) =>
                          setWorkoutData({ ...workoutData, motivation })
                        }
                        className="mt-4"
                        classNames={{
                          base: cn(
                            'inline-flex flex-row-reverse w-full max-w-md items-center',
                            'justify-between cursor-pointer rounded-lg gap-2 p-4 border-2 border-gray-200/30'
                          ),
                        }}
                      >
                        <div className="flex flex-col gap-1">
                          <p className="text-medium">
                            {t('forms.labels.motivationalPhrases')}
                          </p>
                          <p className="text-tiny text-default-500">
                            {t('forms.placeholders.motivationalPhrases')}
                          </p>
                        </div>
                      </Switch>
                    </div>
                  </div>
                </Tab>
                <Tab key="exercises" title={t('pages.Workout.exercises')}>
                  <div className="grid grid-cols-1 gap-4 mt-4">
                    {workoutData?.exercises?.map((exercise, idx) => (
                      <ExerciseCard
                        key={`exercise-card-${idx}`}
                        index={idx}
                        workoutData={workoutData}
                        setWorkoutData={setWorkoutData}
                      />
                    ))}
                    <div
                      onClick={() => {
                        const updatedExercises = [
                          ...workoutData.exercises,
                          {
                            name: '',
                            series: '3',
                            reps: '12',
                            interval: 60,
                          },
                        ];

                        setWorkoutData({
                          ...workoutData,
                          exercises: updatedExercises,
                        });
                      }}
                      className="flex flex-col justify-center min-h-[120px] sm:min-h-0 w-full h-full border-dotted border-3 border-gray-500 cursor-pointer p-4 text-center"
                    >
                      <div>{t('pages.Workout.addExercise')}</div>
                    </div>
                  </div>
                </Tab>
                <Tab key="conclusion" title={t('pages.Workout.conclude')}>
                  <div className="grid grid-cols-3 gap-4 mt-2 sm:mt-6">
                    <Card className="col-span-3 sm:col-span-1 max-h-[250px] sm:min-h-[300px] sm:max-h-[300px]">
                      <CardHeader className="absolute z-10 top-1 flex-col !items-end">
                        <div
                          className="backdrop-blur-md rounded p-1"
                          onClick={() => onPhotoPickerOpen()}
                        >
                          <FontAwesomeIcon icon={faImage} inverse />
                        </div>
                      </CardHeader>
                      <Image
                        removeWrapper
                        alt="Card background"
                        className="z-0 w-full h-full object-cover"
                        src={workoutImages.find((img) =>
                          img.includes(workoutData.image)
                        )}
                      />
                      <CardFooter className="absolute bottom-0 z-10 grid grid-cols-1 text-left">
                        <p className="text-lg text-white/80 uppercase font-bold col-span-1">
                          {t('standalone.workout')} {workoutData.name}
                        </p>
                        {workoutData?.description && (
                          <p className="text-sm text-white/80 uppercase font-bold col-span-1">
                            {workoutData?.description}
                          </p>
                        )}
                      </CardFooter>
                    </Card>
                    <div className="col-span-3 sm:col-span-2">
                      <Table aria-label="exercisesTable">
                        <TableHeader
                          columns={[
                            {
                              key: 'name',
                              label: t('pages.Workout.exercise'),
                            },
                            {
                              key: 'series',
                              label: t('pages.Workout.series'),
                            },
                            {
                              key: 'reps',
                              label: t('pages.Workout.reps'),
                            },
                            {
                              key: 'interval',
                              label: t('pages.Workout.interval'),
                            },
                          ]}
                        >
                          {(column) => (
                            <TableColumn key={column.key}>
                              {column.label}
                            </TableColumn>
                          )}
                        </TableHeader>
                        <TableBody
                          items={workoutData.exercises.map(
                            (exercise, index) => ({
                              ...exercise,
                              key: index,
                              interval: `${String(Math.floor(exercise.interval / 60)).padStart(1, '0')}:${String(exercise.interval % 60).padStart(2, '0')}`,
                            })
                          )}
                        >
                          {(item) => (
                            <TableRow key={item.key}>
                              {(columnKey) => (
                                <TableCell>
                                  {getKeyValue(item, columnKey)}
                                </TableCell>
                              )}
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                      {workoutData?.exercises.find(
                        (exercise) => exercise?.howTo
                      ) && (
                        <Table aria-label="executionTable" className="mt-4">
                          <TableHeader
                            columns={[
                              {
                                key: 'name',
                                label: t('pages.Workout.exercise'),
                              },
                              {
                                key: 'howTo',
                                label: t('pages.Workout.execution'),
                              },
                            ]}
                          >
                            {(column) => (
                              <TableColumn key={column.key}>
                                {column.label}
                              </TableColumn>
                            )}
                          </TableHeader>
                          <TableBody
                            items={workoutData.exercises.map(
                              (exercise, index) => ({
                                ...exercise,
                                key: index,
                                interval: `${String(Math.floor(exercise.interval / 60)).padStart(1, '0')}:${String(exercise.interval % 60).padStart(2, '0')}`,
                              })
                            )}
                          >
                            {(item) =>
                              item?.howTo && (
                                <TableRow key={item.key}>
                                  {(columnKey) => (
                                    <TableCell>
                                      {getKeyValue(item, columnKey)}
                                    </TableCell>
                                  )}
                                </TableRow>
                              )
                            }
                          </TableBody>
                        </Table>
                      )}
                      <form
                        className="mt-4 w-full text-center sm:text-right mb-10 sm:mb-0"
                        onSubmit={handleSubmit(async (data) => {
                          const hasEmptyExerciseName =
                            workoutData.exercises.find(
                              (exercise) => exercise.name.length === 0
                            );

                          if (workoutData?.description.length === 0) {
                            return toast.error(
                              t('notifications.emptyWorkoutDesc')
                            );
                          }

                          if (hasEmptyExerciseName) {
                            return toast.error(
                              t('notifications.emptyExerciseName')
                            );
                          }

                          /**
                           * Create a new workout
                           */
                          if (!workoutId) {
                            trackEvent(
                              'workout_create',
                              `Create Workout: ${workoutData?.description}`,
                              'workout',
                              true
                            );

                            await createWorkoutMutation({
                              variables: {
                                workoutInput: workoutData,
                              },
                            });

                            await refetchWorkouts();

                            navigate('/workouts?sc=true');
                          } else {
                            trackEvent(
                              'workout_update',
                              `Update Workout: ${workoutData?.description}`,
                              'workout',
                              true
                            );

                            await updateWorkoutMutation({
                              variables: {
                                workoutInput: workoutData,
                                workoutId,
                              },
                            });

                            await refetchWorkouts();

                            navigate('/workouts?su=true');
                          }
                        })}
                      >
                        <>
                          {isAIGenerated && (
                            <Button
                              variant="ghost"
                              className="w-full mb-2 sm:w-auto sm:mr-2 sm:mb-0"
                              onPress={() => {
                                trackEvent(
                                  'workout_generate_ai_again',
                                  'Workout - Generate AI Again',
                                  'workout',
                                  true
                                );

                                onModalIAOpen();
                              }}
                            >
                              {t('pages.Workout.generateAgain')}
                            </Button>
                          )}
                          <Button
                            color="primary"
                            className="w-full sm:w-auto text-white"
                            type="submit"
                            isLoading={
                              isLoadingCreateWorkout || isLoadingUpdateWorkout
                            }
                            isDisabled={
                              isLoadingCreateWorkout || isLoadingUpdateWorkout
                            }
                          >
                            {workoutId
                              ? t('pages.Workout.updateWorkout')
                              : t('pages.Workout.createWorkout')}
                          </Button>
                        </>
                      </form>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
